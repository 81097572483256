<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-03 15:53:30
-->
<script>
import TablePage from '@/found/components/table_page';
// import configs from './data';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      requestUrl: '/mdm/m/availableList/list',
      // configs,
      formConfig: {},
    };
  },
  async created() {
    // this.getConfigList('dms_cus_avail');
    const columns = await this.getConfigList('dms_cus_avail');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => (row.updateDate == null ? '' : `${row.updateDate} ${row.updateDateSecond}`);
      }
      if (rowData.field === 'updateName') {
        rowData.formatter = ({ row }) => (row.updateName == null ? '' : `${row.updateName}`);
      }
      return rowData;
    });
  },
  methods: {
    // deleteClick({ val }) {
    //   let datas = [];
    //   if (this.selectRow) {
    //     this.selectRow.forEach((v) => {
    //       datas.push(v.id);
    //     });
    //   } else {
    //     datas = [val.id];
    //   }
    //   if (datas.length !== 0) {
    //     request.post('/mdm/m/availableList/delByIds', datas)
    //       .then((res) => {
    //         if (res.success) {
    //           this.$message({
    //             type: 'success',
    //             message: '删除成功',
    //           });
    //           this.getList();
    //         }
    //       });
    //   } else {
    //     this.$message({
    //       type: 'error',
    //       message: '请传入参数',
    //     });
    //   }
    // },
  },
};
</script>
